import React from 'react';
import Layout from '../../components/Layout';

const SignIn = () => (
  <Layout>
    <div>
      <h1>sign in</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Layout>
);

export default SignIn;
